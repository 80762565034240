import React from 'reactn'
import { Components } from './components/components'

class App extends React.Component {
  render () {
    return (
      <div style={{ textAlign: 'center' }}>
        <Components />
      </div>
    )
  }
}

export default App
