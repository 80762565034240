import React from 'react'

class Button extends React.Component {
  render () {
    return (
      <div className='btns' id={this.props.id} style={{
        borderRadius: 10,
        cursor: 'pointer',
        background: this.props.color,
        display: this.props.display,
        color: 'white'
      }} onClick={() => {
        this.props.onClick()
      }} > <b> { this.props.text } </b>
      </div>
    )
  }
}

export default Button
