import React from 'reactn'
import logo from '../plus-sign-in-a-black-circle.svg'
var valInput
var valDaRest

class Counter extends React.Component {
  // Diminuisce il numero di dev o exec effettuando gli opportuni controlli ( > 0 )
  less () {
    this.shError('white', 'hidden')
    // Se il valore inserito è accettabile lo ritorno, altrimenti ritorno 0
    if (this.props.value > 0 && this.props.value < 16) {
      document.getElementById(this.props.nB).value = this.props.value + this.props.incrementbtn2
      return this.props.value + this.props.incrementbtn2
    } else { return 0 }
  }

  componentDidMount () {
    this.setZero()
    this.props.mod === 'priceExec' ? document.getElementById('priceExec').value = 80 : document.getElementById('priceDev').value = 30
  }

  // Resetta il valore della inputbox dei valori di nExec e nDev a 0
  setZero () {
    if (document.getElementById(this.props.nB).value === '') { document.getElementById(this.props.nB).value = 0 }
  }

  // Modifica i valori di nDev e nExec quando sono inseriti dalla inputbox ed effettua gli opportuni controlli ( > 0 && < 15 && IsNan )
  updateN () {
    // trovo il valore inserito nella inputbox
    valInput = document.getElementById(this.props.nB).value
    // controllo che il carattere inserito sia un numero
    if (isNaN(valInput.substr(valInput.length - 1, 1))) {
      // Se non è un numero lo tolgo dalla stringa
      valInput = valInput.substr(0, valInput.length - 1)
      // Restituisco il valore senza il carattere non numerico
      document.getElementById(this.props.nB).value = valInput
      // Effettuo i controlli e restituisco il valore
      valDaRest = this.controls()
      return valDaRest
    } else {
      document.getElementById(this.props.nB).value = valInput
      // Effettuo i controlli e restituisco il valore
      valDaRest = this.controls()
      return valDaRest
    }
  }

  // Effettua i controlli e fa comparire un messaggio di errore nel caso in cui si sia
  controls () {
    this.shError('white', 'hidden')
    // Se la inputbox rimane senza valore ci scrivo all'interno 0
    if (valInput === '') { return 0 }
    // Se il valore inserito  è accettabile lo ritorno
    if (valInput !== '' && valInput < 16) {
      return parseInt(valInput)
    // Se il valore è maggiore di 15, faccio comparire il messaggio di errore e reimposto il valore a 15 (valore massimo consentito)
    } else if (valInput >= 15) {
      document.getElementById(this.props.nB).value = 15
      this.shError('red', 'visible')
      // Dopo 2 secondi faccio scomparire in automatico il messaggio di errore
      setTimeout(() => {
        this.shError('white', 'hidden')
      }, 2000)
      return 15
    }
  }

  shError (color, visibility) {
    document.getElementById(this.props.nB).style.background = color
    document.getElementById('error').style.visibility = visibility
  }

  // Aumenta il numero di dev o exec effettuando gli opportuni controlli ( < 15 )
  add () {
    this.shError('white', 'hidden')
    // Se il valore è minore di 15 posso effettuare l'incremento
    if (this.props.value < 15) {
      document.getElementById(this.props.nB).value = this.props.value + this.props.incrementbtn1
      return this.props.value + this.props.incrementbtn1
      // Altrimenti faccio comparire il messaggio di errore e ritorno 15 ( il massimo valore consentito )
    } else {
      this.shError('red', 'visible')
      // Dopo 2 secondi faccio scomparire il messaggio di errore
      setTimeout(() => {
        this.shError('white', 'hidden')
      }, 2000)
      return 15
    }
  }

  checkMod () {
    if (document.getElementById(this.props.mod).value === '') {
      this.props.mod === 'priceDev' ? document.getElementById(this.props.mod).value = 30 : document.getElementById(this.props.mod).value = 80
    }
  }

  render () {
    return (
      <React.Fragment>
        <div className='prices'>
          <input type='text' id={this.props.mod} placeholder='Prezzo' style={{
            maxWidth: 45,
            boxSizing: 'border-box',
            textAlign: 'center',
            marginBottom: 5
          }} onBlur={() => this.checkMod()} />
          <label id='lbl'> €/Ora </label>
        </div>
        <div className='majorContainer'>
          <div src={logo} id='btnPlus' className='buttons'
            onClick={() => {
              this.props.onChange(this.add())
            }} />
          <input className='input' id={this.props.nB} onBlur={() => this.setZero()} type='text' onChange={() => { this.props.updateN(this.updateN()) }} style={{
            width: '100%',
            maxWidth: 100,
            boxSizing: 'border-box',
            textAlign: 'center'
          }} />
          <div id='btnMinus' className='buttons'
            onClick={() => {
              this.props.onChange(this.less())
            }} />
        </div>
        <p id='error' style={{ color: 'red', visibility: 'hidden' }}> <b> Valore massimo: 15 </b></p>
      </React.Fragment>
    )
  }
}

export default Counter
