import { setGlobal, getGlobal } from 'reactn'
import '../App.css'
import moment from 'moment'

export var pass = false

// Faccio partire il timer che aggiornerà totalMeeting
export const updatePrice = () => {
  const { nDev, nExec, interval, pricePerSec } = getGlobal()
  setGlobal({
    timerActive: true,
    interval: setInterval(() => {
      if (nDev !== 0 || nExec !== 0) {
        doUpdate()
      } else clearInterval(interval)
    }, Math.floor(1000 / pricePerSec))
  })
}

// Calcolo l'intervallo di tempo che passa tra gli aggiornamenti di totalMeeting
export const updateInterval = () => {
  const { nDev, nExec, interval, priceDev, priceExec } = getGlobal()
  setGlobal({
    pricePerSec: ((priceDev * nDev) + (priceExec * nExec)) / 36
  })
  interval && clearInterval(interval)
  updatePrice()
}

// Effettuo l'aggiornamento di totalMeeting, incrementandolo di 0.01
export const doUpdate = () => {
  const { totalMeeting } = getGlobal()
  setGlobal({
    totalMeeting: totalMeeting + 0.01
  })
}

// Aggiorna il valore di nDev effettuando gli opportuni controlli (il valore preso da input non sia ' ' o che nExec sia !== 0 [altrimenti il tier si fermerebbe quando non dovrebbe])
export const updateStateDev = (n) => {
  const { nExec, interval } = getGlobal()
  if (n !== '' || nExec !== 0) {
    setGlobal({
      nDev: n
    })
  } else {
    setGlobal({
      timerActive: false
    })
    clearInterval(interval)
  }
}

// Aggiorna il valore di nExec effettuando gli opportuni controlli (il valore preso da input non sia ' ' o che nDev sia !== 0 [altrimenti il tier si fermerebbe quando non dovrebbe])
export const updateStateExec = (n) => {
  const { nDev, interval } = getGlobal()
  if (n !== '' || nDev !== 0) {
    setGlobal({
      nExec: n
    })
  } else {
    setGlobal({
      timerActive: false
    })
    clearInterval(interval)
  }
}

// Si scatena alla pressione di "STOP": ferma il timer e salva i valori nDev, nExec e totalMeeting per farli ripartire da dov'erano alla pressione di "START"
export const stopTimer = () => {
  const { interval, update, totalMeeting } = getGlobal()
  setGlobal({
    timerActive: false,
    previousPrice: totalMeeting
  })
  clearInterval(interval)
  clearInterval(update)
}

// Si scatena alla pressione di "START": avvia il timer per il conteggio di totalMeeting, il timer per l'aggiornamento ( 5s ) e salva l'ora attuale per permettere di effettuare l'aggiornamento
export const startTimer = () => {
  const { nDev, nExec } = getGlobal()
  if (nDev !== 0 || nExec !== 0) {
    pass = controlsOnPrice()
    if (pass) {
      setGlobal({
        initialTime: moment().unix(),
        timerActive: true,
        modifyState: false,
        priceDev: document.getElementById('priceDev').value,
        priceExec: document.getElementById('priceExec').value,
        update: setInterval(() => {
          checkPrice()
        }, 5000)
      })
      document.getElementById('modify').style.display = 'block'
      document.getElementById('reset').style.display = 'block'
      updateInterval()
      shModify('none', 'sos 1s', 'bruh 0s')
    }
  }
}

const controlsOnPrice = () => {
  if (document.getElementById('priceDev').value === '0' || isNaN(document.getElementById('priceDev').value)) {
    control('priceDev', 'priceExec', 30)
  } else if (document.getElementById('priceExec').value === '0' || isNaN(document.getElementById('priceExec').value)) {
    control('priceExec', 'priceDev', 80)
  } else if (isNaN(document.getElementById('priceDev').value)) {
    if (isNaN(document.getElementById('priceExec').value)) doubleError('priceDev', 'priceExec')
    else error('priceDev', 30)
  } else if (isNaN(document.getElementById('priceExec').value)) {
    error('priceExec', 80)
  } else return true
}

const control = (fP, sP, price) => {
  if (document.getElementById(sP).value === '0' && document.getElementById(fP).value === '0') {
    doubleError()
  } else {
    error(fP, price)
  }
}

const error = (fP, price) => {
  document.getElementById(fP).style.background = 'red'
  setTimeout(() => {
    document.getElementById(fP).style.background = 'white'
    document.getElementById(fP).value = price
  }, 1000)
  return false
}

const doubleError = () => {
  document.getElementById('priceDev').style.background = 'red'
  document.getElementById('priceExec').style.background = 'red'
  setTimeout(() => {
    document.getElementById('priceDev').style.background = 'white'
    document.getElementById('priceExec').style.background = 'white'
    document.getElementById('priceDev').value = 30
    document.getElementById('priceExec').value = 80
  }, 1000)
  return false
}

// Si scatena alla pressione di "RESET": aggiorna semplicemente la pagina
export const reset = () => {
  window.location.reload()
}

// Si scatena alla pressione di "MODIFY": mostra o nasconde il form per modificare i valori nDev e nExec
export const modify = () => {
  const { modifyState, interval, update } = getGlobal()
  if (modifyState) {
    shModify('none', 'sos 1s', 'bruh 0s')
    setGlobal({
      modifyState: false
    })
  } else {
    shModify('block', 'sosI 1s', 'bruhI 2s')
    setGlobal({
      modifyState: true
    })
    document.getElementById('modify').style.display = 'none'
    clearInterval(interval)
    clearInterval(update)
  }
}

// Nasconde o mostra il form di modifica di nDev e nExec
const shModify = (mode, fun, fun2) => {
  const num = [4, 2, 2, 2]
  const elem = ['buttons', 'input', 'description', 'prices']
  for (var i = 0; i < 4; i++) hideElements(num[i], elem[i], mode)
  animations('price', fun)
  animations('littleContainer', fun2)
}

const animations = (el, fun) => {
  document.getElementById(el).style.webkitAnimation = fun
  document.getElementById(el).style.animation = fun
  document.getElementById(el).style.animationFillMode = 'forwards'
}

const hideElements = (n, el, fun) => {
  for (var i = 0; i < n; i++) { document.getElementsByClassName(el)[i].style.display = fun }
}

// Effettua l'aggiornamento di totalMeeting ogni 5 secondi per prevenire desync e fare progredire totalMeeting anche se la pagina è in background
const checkPrice = () => {
  const { initialTime, nDev, priceDev, nExec, priceExec, previousPrice } = getGlobal()
  // Faccio la differenza tra l'orario attuale e quello in cui ho premuto "START"
  var time = moment().unix() - initialTime
  // Calcolo il prezzo attuale molitplicando il prezzo per ogni secondo e moltiplicandolo per time
  var actual = (((nDev * priceDev) + (nExec * priceExec)) * time) / 3600
  // Aggiungo il prezzo calcolato con quello salvato alla pressione di "START"
  var totMeeting = previousPrice + actual
  // Aggiorno totalMeeting nel global
  setGlobal({
    totalMeeting: totMeeting
  })
}
