import React from 'react'
import '../App.css'

class Price extends React.Component {
  render () {
    return (
      <div id='price'><b> { this.props.text } </b>
      </div>
    )
  }
}

export default Price
