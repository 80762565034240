import React from 'reactn'
import Counter from './Counter'
import Price from './Price'
import Button from './Button'
import '../App.css'
import { updateStateDev, updateStateExec, stopTimer, startTimer, reset, modify } from './CalculateTime'

export class Components extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      display: 'none'
    }
    this.setGlobal({
      totalMeeting: 0, // Prezzo che compare a schermo e che si aggiorna a ogni ciclo
      nDev: 0, // Numero di sviluppatori presenti alla riunione
      nExec: 0, // Numero di exec presenti alla riunione
      timerActive: false, // Stato del timer (false = disattivo; true = attivo)
      pricePerSec: 0, // Costo della riunine per ogni secondo
      interval: null, // Contiene il setInterval per l'aggiornamento di totalMeeting
      priceDev: 30, // Prezzo sviluppatori orario
      priceExec: 80, // Prezzo exec orario
      initialTime: 0, // Orario in cui viene premuto il tasto "START"
      modifyState: false, // Stato del form di modifica di nDev e nExec
      update: null, // Contiene il setInterval per l'aggiornamento ogni 5 secondi
      previousPrice: 0 // Contiene il prezzo di quando è stato premuto il tasto "STOP"
    })
  }

  // Cambia colore e testo al tasto "START/STOP" in base allo stato del timer
  handleClick (text, color) {
    this.global.timerActive ? stopTimer() : startTimer()
    text = this.global.timerActive ? 'START' : 'STOP'
    color = this.global.timerActive ? 'red' : 'green'
    return (text, color)
  }

  // Cambia colore e testo al tasto "START/STOP" quando si preme il tasto "MODIFIY"
  changeColor (text, color) {
    text = 'START'
    color = 'green'
    stopTimer()
    return (text, color)
  }

  render () {
    const { totalMeeting, timerActive, nDev = 0 } = this.global
    const { nExec = 0 } = this.global
    const buttonLabel = timerActive ? 'STOP' : 'START'
    const buttonColor = timerActive ? 'red' : 'green'
    return (
      <React.Fragment>
        <div className='App' >
          <Price text={parseFloat(totalMeeting).toFixed(2) + '€'} />
          <div id='bigContainer'>
            <div id='littleContainer'>
              <div id='cont1'>
                <h3 className='description'> Developers </h3>
                <Counter mod='priceDev' textbtn1='+' nB='btn1' value={nDev} incrementbtn1={1} incrementbtn2={-1} updateN={nDev => { updateStateDev(nDev) }} onChange={nDev => { updateStateDev(nDev) }} textbtn2='-' />
              </div>
              <div id='cont2'>
                <h3 className='description'> Exec </h3>
                <Counter mod='priceExec' textbtn1='+' nB='btn2' value={nExec} incrementbtn1={1} incrementbtn2={-1} updateN={nExec => { updateStateExec(nExec) }} onChange={nExec => updateStateExec(nExec)} textbtn2='-' />
              </div>
            </div>
            <div id='allButtons'>
              <Button id='ss' text={buttonLabel} visibility='visible' color={buttonColor} onClick={() => this.handleClick(buttonLabel, buttonColor)} />
              <Button id='modify' text='MODIFY' display={this.state.display} color='orange' onClick={() => { this.changeColor(buttonLabel, buttonColor); modify() }} />
              <Button id='reset' text='RESET' display={this.state.display} color='blue' onClick={() => reset()} />
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Components
